<template>
  <div class="grid grid-cols-3">
    <div class="col-span-1">
      <div
        class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1 mx-1"
      >
        <div class="px-3 py-4 sm:p-4">
          <DonutChart
            :dataSeries="monthlyDataExtensionsSeries"
            :chartLabel="monthlyDataExtensionsLabels"
            title="Answered calls count extensions"
          />
        </div>
      </div>
      <div
        class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1 mx-1"
      >
        <div class="px-3 py-4 sm:p-4">
          <DonutChart
            :dataSeries="monthlyDataQueuesSeries"
            :chartLabel="monthlyDataQueuesLabels"
            title="Answered calls count queues"
          />
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <div
        class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1 mx-1"
      >
        <div class="px-3 py-4 sm:p-4">
          <DonutChart
            :dataSeries="seriesTotal"
            :chartLabel="labelTotal"
            title="Answered calls count total"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import DonutChart from "../components/charts/DonutChart.vue";

export default {
  name: "Home",
  props: ["seriesTotal", "labelTotal"],
  components: {
    DonutChart,
  },
  data() {
    return {
      pbx3cxData: [],
      callData: [],
      callDataHistory: [],
      callDataParticipants: [],
      callDataSegments: [],
      hostName: "demo-encom.3cx.fr",
      limit: 1,
      pbxExtensions: [],
      pbxQueues: [],
      monthlyDataExtensions: [],
      monthlyDataExtensionsSeries: [],
      monthlyDataExtensionsLabels: [],
      monthlyDataQueuesSeries: [],
      monthlyDataQueuesLabels: [],
    };
  },
  methods: {
    sortDateTime(data) {
      data.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });
    },
    formatDateToTime(date) {
      if (date) {
        let hours = ("0" + new Date(date).getHours()).slice(-2);
        let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
        let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "null";
      }
    },
    async getPBXData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cxCalls?host_name=${
            this.hostName
          }&limit=${this.limit}`
        );
        console.log(res.data);

        this.callDataHistory = res.data.history;
        this.callDataSegments = res.data.segments;
        this.callDataParticipants = res.data.participants;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getPbx3cxData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cx/3/origins`
        );
        console.log(res.data);
        let keys = Object.keys(res.data);
        let extensions = [];
        let queues = [];
        for (let index = 0; index < keys.length; index++) {
          if (keys[index].includes("extension")) {
            let spliceKey = keys[index].split(":");
            extensions.push(spliceKey[1]);
          }
          let spliceQueueKey = keys[index].split(":");
          if (spliceQueueKey[0].includes("queue")) {
            let spliceKey = keys[index].split(":");
            queues.push(spliceKey[1]);
          }
        }
        this.pbxExtensions = extensions.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        });
        this.pbxQueues = queues.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        });
        return res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getMontlyPbxDataForExtensionOrQueue(numb) {
      let data = await this.getPbx3cxData();
      let keys = Object.keys(data);
      let montlyTotal = undefined;
      for (let index = 0; index < keys.length; index++) {
        let spliceQueueKey = keys[index].split(":");
        if (
          numb === spliceQueueKey[1] &&
          spliceQueueKey[2] === this.currentMonthAndYearForPbx
        ) {
          montlyTotal = data[keys[index]];
        }
      }
      console.log(montlyTotal);
    },
    async getMontlyPbxDataForExtensions() {
      let data = await this.getPbx3cxData();
      let keys = Object.keys(data);
      let chartData = [];

      for (let index = 0; index < keys.length; index++) {
        let spliceQueueKey = keys[index].split(":");
        if (
          spliceQueueKey[0] === "extension" &&
          spliceQueueKey[2] === this.currentMonthAndYearForPbx
        ) {
          for (
            let extIndex = 0;
            extIndex < this.pbxExtensions.length;
            extIndex++
          ) {
            if (spliceQueueKey[1] === this.pbxExtensions[extIndex]) {
              let obj = {};
              // obj["extension"] = this.pbxExtensions[extIndex];
              // obj["value"] = data[keys[index]];
              // this.monthlyDataExtensions.push(obj);
              this.monthlyDataExtensionsSeries.push(
                parseInt(data[keys[index]])
              );
              this.monthlyDataExtensionsLabels.push(
                this.pbxExtensions[extIndex]
              );
            }
          }
        }
      }
    },
    async getMontlyPbxDataForQueues() {
      let data = await this.getPbx3cxData();
      let keys = Object.keys(data);
      let chartData = [];

      for (let index = 0; index < keys.length; index++) {
        let spliceQueueKey = keys[index].split(":");
        if (
          spliceQueueKey[0] === "queue" &&
          spliceQueueKey[2] === this.currentMonthAndYearForPbx
        ) {
          for (let extIndex = 0; extIndex < this.pbxQueues.length; extIndex++) {
            if (spliceQueueKey[1] === this.pbxQueues[extIndex]) {
              let obj = {};
              // obj["extension"] = this.pbxQueues[extIndex];
              // obj["value"] = data[keys[index]];
              // this.monthlyDataExtensions.push(obj);
              this.monthlyDataQueuesSeries.push(parseInt(data[keys[index]]));
              this.monthlyDataQueuesLabels.push(this.pbxQueues[extIndex]);
            }
          }
        }
      }
    },
  },
  mounted() {
    // this.getPBXData();
    // this.getPbx3cxData();
    this.getMontlyPbxDataForExtensions();
    this.getMontlyPbxDataForQueues();
  },
  computed: {
    currentMonthAndYearForPbx() {
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
      return String(year) + String(month);
    },
  },
  watch: {
    limit: function(val) {
      if (val > 0) this.getPBXData();
      else this.limit = 1;
    },
  },
};
</script>

<style>
.panel_width {
  max-width: fit-content;
}
</style>
